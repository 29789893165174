<template>
    <div>
        <div class="projectHeader">
            <a style="float: left;line-height: 2rem; font-size: 1.25rem;margin-left: 1rem; font-weight: 600;">
                {{projectName}}
            </a>
            <div style="float: right; padding-top: -0.5rem;margin-bottom: 0.5rem;">
                <Dropdown trigger="click" placement="bottom-end" style="float: right;">
                    <Button type="success" style="width: 130px;">
                        <Icon type="ios-cart-outline"/>
                        Invest/Buy
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list" style="width: 14rem;">
                        <Button type="text" class="buyListItem" @click="buyClick('buy')">
                            <span style="float: left;">Executable code:</span>
                            <span style="float: right;">{{ECPrice}}</span>
                        </Button>
                        <Button type="text" class="buyListItem" @click="buyClick('invest')">
                            <span style="float: left;">Source code:</span>
                            <span style="float: right;">{{SCPrice}}</span>
                        </Button>
                    </DropdownMenu>
                </Dropdown>
            </div>
            <div style="float: right; margin: 0 0.5rem;">
                <Button type="info" style="width: 6rem;">
                    <div @click="joinClick()" v-if="!isMember">
                        <Icon type="md-person-add"/>
                        Join
                    </div>
                    <div @click="checkClick()" v-if="isMember">
                        <Icon type="md-add"/>
                        Extend
                    </div>
                </Button>
            </div>

        </div>
        <Row style="background-color: #FFF; width: 100%; margin-left: 0rem; margin-right: 0; padding-top: 0.3rem; padding-bottom: 2rem;"
             :gutter="24">
            <Col span="5" style="padding-left: 2rem; ">
                <Card style="padding-bottom: 0.5rem; margin-top: 1rem;">
                    <p slot="title" style="text-align: left;">
                        <Icon type="ios-analytics-outline"/>
                        Project information
                    </p>
                    <div class="listItem">
                        Creation Time:
                        <span style="float: right;">{{creationTime}}</span>
                    </div>
                    <div class="listItem">
                        Last updated:
                        <span style="float: right;">{{lastUpdated}}</span>
                    </div>
                    <div class="listItem">
                        Price of executable code:
                        <span style="float: right;">{{ECPrice}}</span>
                    </div>
                    <div class="listItem">
                        Share points gain ratio:
                        <span style="float: right;">{{pointsPerLine}}</span>
                    </div>
                    <div class="listItem">
                        Number of investors:
                        <span style="float: right;">{{investorNum}}</span>
                    </div>
                    <div class="listItem">
                        Number of contributors:
                        <span style="float: right;">{{ContributorNum}}</span>
                    </div>
                    <div class="listItem">
                        Number of functions:
                        <span style="float: right;">{{functionNum}}</span>
                    </div>
                    <div class="listItem">
                        Lines of Master:
                        <span style="float: right;">{{LinesOfMaster}}</span>
                    </div>
                    <div class="listItem">
                        Estimated release time:
                        <span style="float: right;">{{EstimatedRT}}</span>
                    </div>
                    <div class="listItem">
                        Voting time for Join/Extend:
                        <span style="float: right;">{{VotingTimeForJoin}}</span>
                    </div>
                    <div class="listItem">
                        Voting time for amendment:
                        <span style="float: right;">{{VTForAmendment}}</span>
                    </div>
                    <div class="listItem">
                        Has executable code
                        <Icon v-if="HasECode" style="float: right;" type="md-checkmark"/>
                        <Icon v-if="!HasECode" style="float: right;" type="md-close"/>
                    </div>
                </Card>
                <div style="width: 100%;margin-top: 20px;float: left">
                    <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :before-upload="handleBeforeUpload"
                            type="select"
                            action=""
                            style="">
                        <Button style="width: 100%" type="primary" size="large">
                            Upload Executable Code
                        </Button>
                    </Upload>

                    <Button v-if="isFounder" type="error" style="margin-top: 20px" size="large" @click="deleteShow">
                        Delete Project
                    </Button>
                </div>
            </Col>
            <Modal v-model="deleteModal">
                <p slot="header" style="text-align:left">
                    <span>Are you absolutely sure?</span>
                </p>
                <div style="padding-top: 0px;">
                    <div>
                        <span style="font-size: 0.85rem; color: gray">
                                <Icon color='#D4AC0D' type="ios-warning-outline" size="22"/>
                            <p style="display: inline">This action cannot be undone. This will permanently delete the project <span
                                    style="color: #17233d;font-size: 16px">{{projectName}}</span>.</p>
                            <p>Please type <span style="color: #17233d;font-size: 16px">{{projectName}}</span> to confirm.</p>
                        </span>
                    </div>
                    <input v-model="deleteInput" style=" margin-top:10px; width: 80%; margin-left: 10%"></input>
                </div>
                <div slot="footer">
                    <Button type="error" size="large" long @click="deleteOk" :disabled="projectName!==deleteInput">I
                        understand the consequences, delete this project.
                    </Button>
                </div>
            </Modal>
            <Col span="19" style="padding-left: 1rem; padding-right: 2rem;">
                <div style="width: 100%;padding-left:3%;float: left;margin-top: 20px">
                    <div style="width:100%;float:left;font-size: 1.25rem;font-weight:500;text-align: left">
                        Project information edit
                    </div>

                    <div style="width:100%;font-size:1rem;float:left;font-weight:500; color:darkgray;text-align: left">
                        As an owner, you can launch a vote or join in vote to change project information.
                    </div>
                    <div style="width:100%;font-size:1rem;float:left;font-weight:500; color:darkgray;text-align: left">
                        If you need executable code, you can download it in this page.
                    </div>

                    <!--<div style="width: 80%; float: left;text-align: left;margin-top: 20px;">
                        {{abstract}}
                    </div>-->
                </div>
                <div style="width: 100%;float: left;margin-top: 20px">
                    <Menu mode="horizontal" theme="light" :active-name="activeTab" @on-select='menuSelect'>
                        <MenuItem name="2">
                            <Icon type="ios-people"/>
                            Create
                        </MenuItem>
                        <MenuItem name="1">
                            <Icon type="ios-paper"/>
                            Vote
                        </MenuItem>
                    </Menu>
                    <router-view></router-view>
                </div>
            </Col>
        </Row>
    </div>
</template>

<script>
    import ProjectVersion from "../components/ProjectVersion";

    export default {
        components: {ProjectVersion},
        data() {
            return {
                projectId: '',
                isMember: false,
                founder: '',
                isFounder: false,
                projectName: '',
                activeTab: '',
                abstract: '',
                environment: '',
                languages: '',
                dependencies: '',
                creationTime: '',
                lastUpdated: '',
                totalInvestment: '',
                investorNum: 0,
                ContributorNum: 0,
                totalprofits: 0,
                functionNum: 0,
                ECPrice: "",
                SCPrice: "",
                userProfit: '',
                userConPt: '',
                userExecutedPt: '',
                userAvailablePt: '',
                userRank: 0,
                userConRank: 0,
                profitChange: '',
                EstimatedRT: '',
                pointsPerLine: '',
                LinesOfMaster: '123',
                VotingTimeForJoin: '',
                VTForAmendment: '',
                HasECode: true,
                ECpath: '',
                favBtnType: 'default',
                favNum: 0,
                deleteModal: false,
                deleteInput: '',

            }
        },
        methods: {
            favBtnClick() {
                switch (this.favBtnType) {
                    case 'primary':
                        this.favBtnType = 'default'
                        break;
                    case 'default':
                        this.favBtnType = 'primary'
                        break;
                }
            },
            deleteShow() {
                this.deleteInput = "";
                this.deleteModal = true
            },
            deleteOk() {
                let Tself = this;
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'delete',
                    url: '/project/delete',
                    data: this.projectId,
                }).then(function (response) {
                    Tself.$Message.success("success!")
                    Tself.$router.push("/")
                }).catch((error) => {
                    console.log(error)
                    Tself.$Message.error(error.message);
                })

            },
            buyClick(val) {
                if (val == "buy") {
                    this.$router.push('/payment?mode=buy&projectId=' + this.projectId);
                } else {
                    this.$router.push('/payment?mode=invest&projectId=' + this.projectId);
                }
            },
            joinClick() {
                this.$router.push('/createJoin?id=' + this.projectId)
            },
            checkClick() {
                this.$router.push('/checkJoin?id=' + this.projectId)
            },
            cloudDemo() {

            },
            handleBeforeUpload(file) {
                let Tself = this;
                let fd = new FormData();
                fd.append('file', (file))
                this.$axios.post(
                    "/upload",
                    fd,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then((response) => {
                    this.ECpath = response.data.data.filePath;
                    this.HasECode = true;
                    this.$axios.get(
                        "/project/addExecutableCode?projectId=" + this.projectId + "&executableCodePath=" + this.ECpath,
                    ).then((response) => {
                        if (response.data.data.items === "success") {
                            Tself.$Message.success("success")
                        } else {
                            Tself.$Message.error("error")
                        }
                    }).catch((error) => {
                        this.$Message.error(error.message);
                    });
                }).catch((error) => {
                    console.log(error)
                    this.$Message.error(error.message);
                })
            },
            menuSelect(name) {
                switch (name) {
                    case '1':
                        this.$router.push('/projectInfoList?id=' + this.projectId);
                        break;
                    case '2':
                        this.$router.push('/projectInformationEdit?id=' + this.projectId)
                        break;
                }
            },
            init() {
                if (this.$cookies.isKey('user_name')) {
                    var identity = this.$cookies.get('user_identity');
                    if (identity == 'member') {
                        this.isMember = true;
                    } else {
                        this.isMember = false;
                    }
                } else {
                    this.isMember = false;
                }
                var Tself = this;
                var val = this.$route.query.id;
                this.projectId = val;
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/project/findById',
                    data: val
                }).then(function (response) {
                    var data = response.data.data.items;
                    Tself.projectFounderId = data.founder;
                    Tself.environment = data.environment;
                    Tself.projectName = data.name;
                    Tself.abstract = data.abstractInfo;
                    Tself.languages = data.languages;
                    Tself.dependencies = data.dependencies;
                    Tself.creationTime = data.creationTime;
                    Tself.lastUpdated = data.updateTime;
                    Tself.investorNum = data.investorNumber;
                    Tself.ContributorNum = data.contributionNumber;
                    Tself.totalprofits = data.totalProfit;
                    Tself.functionNum = data.functionNumber;
                    Tself.ECPrice = data.executableCodePrice;
                    Tself.LinesOfMaster = data.codeLines;
                    Tself.SCPrice = data.sourceCodePrice;
                    Tself.EstimatedRT = data.releaseTime;
                    Tself.pointsPerLine = data.linesForGetOneShare;
                    Tself.VotingTimeForJoin = data.votingTimeForJoin;
                    Tself.founder = data.founder;
                    Tself.isFounder = (data.founder === Tself.$cookies.get('user_id'))
                    Tself.VTForAmendment = data.votingTimeForAmendment;
                    if (data.executableCodePath != "") {
                        Tself.HasECode = true;
                    } else {
                        Tself.HasECode = false;
                    }
                    Tself.ECpath = data.executableCodePath;
                    Tself.hierarchyDiagramPath = data.hierarchyDiagramPath;
                })
                    .catch(function (error) {
                        console.log(error);
                    });
                if (this.$cookies.isKey('user_name')) {
                    this.$axios({
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        method: 'post',
                        url: '/contribution/findUniqueContribution',
                        data: {
                            projectId: Tself.projectId,
                            userId: Tself.$cookies.get('user_id')
                        }
                    })
                        .then(function (response) {
                            var items = response.data.data.items;
                            Tself.$cookies.set("contributionId", items.id);
                            Tself.isMember = true;
                            Tself.userProfit = items.profit;
                            Tself.userConPt = items.shares;
                            Tself.userAvailablePt = items.shares - items.executedShares;
                            Tself.profitChange = items.profitInThisWeek;
                        })
                        .catch(function (error) {
                            Tself.isMember = false;
                            console.log(error);
                        });
                }
            },
            ask() {
                let Tself = this
                let hasLogin = true;
                let val = Tself.$cookies.get('user_id');
                if (val === null) {
                    hasLogin = false
                }
                if (hasLogin) {
                    this.$router.push('/furtherInfo?id=' + this.projectId)
                } else {
                    this.$Message.warning('please login first');
                }
            },
            runDemo() {
                let Tself = this
                let hasLogin = true;
                let val = Tself.$cookies.get('user_id');
                if (val === null) {
                    hasLogin = false
                }
                if (hasLogin) {
                    //this.$Message.warning('The project has not yet uploaded a demo on the cloud platform');
                    //this.$router.push('/furtherInfo?id='+this.projectId)
                    window.open("http://47.106.171.65:6080/vnc.html?password=123456")
                } else {
                    this.$Message.warning('please login first');
                }
            }
        },
        mounted() {
            this.activeTab = '2';
            this.init();
        }
    }
</script>

<style>
    .projectHeader {
        margin: 20px 0;
        width: 100%;
        height: 1.5rem;
        padding: 0 3.125rem;
    }

    .contentCard {
        background: #ffffff;
        padding: 4rem;
        padding-top: 2rem;
    }

    .introduction {
        text-align: left;
        margin-right: 2rem;
    }

    .introBlock {
        margin: 1rem 0;
    }

    .ivu-btn > span {
        width: 100%;
    }

    .CTitle {
        font-weight: 600;
        font-size: 1.125rem;
    }

    .listItem {
        margin: 0.8rem 0;
        text-align: left;
    }

    .buyListItem {
        width: 100%;
        margin: 0.5rem 0;
    }

    .smallTitle {
        display: block;
        color: darkgray;
        font-size: 0.875rem;
        margin: 0.3rem 0;
    }

    .bigNum {
        display: block;
        text-align: center;
        color: #e9ab01;
    }
</style>
